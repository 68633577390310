@import 'src/styles/media';
@import 'src/styles/typography';

.root {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-right: 64px;
  min-height: 400px;

  @include tablet-landscape {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: unset;
  }
}

.root::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  left: 8.5px;
  border-left: 1px dashed var(--gray-4);

  @include tablet-landscape {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 9px;
    border-bottom: 1px dashed var(--gray-4);
  }
}

.root::after {
  @include tablet-landscape {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 24px;
    border-color: transparent transparent transparent var(--gray-4);
    transform: rotate(0deg);
    right: -8px;
    top: -3px;
  }

}

.milestone {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  position: relative;

  .icon {
    z-index: 1;
    position: relative;
  }


  &.active {
    color: var(--green-1);

    &::before {
      content: '';
      display: block;
      height: 60px;
      width: 3px;
      background: var(--green-1);
      top: 8px;
      left: 9px;
      transform: translateX(-50%);
      position: absolute;
      border-radius: 4px;
    }

    @include tablet-landscape {
      &::before {
        display: none;
      }
    }

    .label {
      color: var(--green-1);
    }
  }

  @include tablet-landscape {
    flex-direction: column;

    &.first {
      align-items: flex-start;
    }

    &.bottom {
      .label {
        bottom: -18px;
      }
    }

    &.active {
      color: var(--green-1);

      .label {
        color: var(--green-1);

        &::before {
          content: '';
          display: block;
          height: 3px;
          width: 80%;
          background: var(--green-1);
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          position: absolute;
          border-radius: 4px;
        }
      }
    }
  }
}

.label {
  @extend %body2;
  color: var(--gray-0);
  left: 32px;
  top: -2px;
  position: absolute;
  white-space: nowrap;
  z-index: 1;

  @include tablet-landscape {
    @extend %body2;
    color: var(--gray-0);
    top: unset;
    left: unset;
    bottom: calc(100% + 4px);
    position: absolute;
    white-space: nowrap;
    z-index: 1;
  }
}

