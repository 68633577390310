@import 'src/styles/typography';
@import 'src/styles/mixins';
@import 'src/styles/media';


.root {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  gap: 16px;
  flex-direction: column;

  @include tablet-landscape {
    align-items: center;
    flex-direction: row;
  }

}

.heading {
  @extend %heading2;
  color: var(--gray-5);
}

.wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 100%;
  overflow: auto;

  @include hide-scrollbar;
}

.item {
  border: 1px solid transparent;
  border-radius: 6px;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: var(--gradient-shade);
  }
}
