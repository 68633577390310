@mixin display1 {
  font-family: var(--font-raleway);
  font-size: 88px;
  font-style: normal;
  font-weight: 500;
  line-height: 108px;
}

@mixin display2 {
  font-family: var(--font-raleway);
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px;
}

@mixin display-mobile {
  font-family: var(--font-raleway);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
}

%display1 {
  @include display1;
}
%display2 {
  @include display2;
}
%display-mobile {
  @include display-mobile;
}

@mixin heading1 {
  font-family: var(--font-raleway);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}

@mixin heading2 {
  font-family: var(--font-raleway);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

@mixin heading3 {
  font-family: var(--font-raleway);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
}

@mixin heading4 {
  font-family: var(--font-raleway);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

@mixin heading5 {
  font-family: var(--font-raleway);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

%heading1 {
  @include heading1;
}
%heading2 {
  @include heading2;
}
%heading3 {
  @include heading3;
}
%heading4 {
  @include heading4;
}
%heading5 {
  @include heading5;
}

@mixin body1 {
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@mixin body2 {
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

@mixin body3 {
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

@mixin body4 {
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

%body1 {
  @include body1;
}
%body2 {
  @include body2;
}
%body3 {
  @include body3;
}
%body4 {
  @include body4;
}

@mixin body2-italic {
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
}

@mixin body1-underlined {
  font-family: var(--font-inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

@mixin body3-underlined {
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-decoration-line: underline;
}

%body2-italic {
  @include body2-italic;
}

%body1-underlined {
  @include body1-underlined;
}
%body3-underlined {
  @include body3-underlined;
}

@mixin caption1 {
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

%caption1 {
  @include caption1;
}

@mixin caption2 {
  font-family: var(--font-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

%caption2 {
  @include caption2;
}

@mixin caption3 {
  font-family: var(--font-inter);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

%caption3 {
  @include caption3;
}

@mixin tagline {
  font-family: var(--font-inter);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

%tagline {
  @include tagline;
}

@mixin digits {
  font-family: var(--font-raleway);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 54px;
}

%digits {
  @include digits;
}

@mixin subtitle1 {
  font-family: var(--font-inter);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
}

%subtitle1 {
  @include subtitle1;
}

@mixin subtitle2 {
  font-family: var(--font-inter);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
}

%subtitle2 {
  @include subtitle2;
}
