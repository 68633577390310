@import 'src/styles/typography';
@import 'src/styles/media';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
}

.title {
  @include display-mobile;
  font-size: 42px;
  font-weight: 700;
  line-height: 52px;
  color: var(--white);

  padding: 24px 0;

  @include tablet-portrait {
    max-width: 70%;
  }

  @include tablet-landscape {
    @include display1;
    font-size: 76px;
    line-height: 88px;
    font-weight: 700;
    padding: 120px 0;

  }
}

.content {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(260px, 1fr) );
  grid-gap: 10px;

  @include tablet-landscape {
    grid-template-columns: repeat( auto-fit, minmax(424px, 1fr) );
  }
}

.span {
  grid-column: span 1;

  @include tablet-portrait {
    grid-column: span 2;
  }
}
