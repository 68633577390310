@import 'src/styles/typography';

.root {
  padding: 40px;
  border-radius: 12px;
  background: var(--dark-bg-2);
  box-shadow: 0 8px 24px 6px rgb(18 20 18 / 80%);
}

.centralize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
}

.title {
  @extend %heading2;
  color: var(--white);
  margin-bottom: 10px;
}

.text {
  @extend %body1;
  color: var(--gray-0);
}

